import { useState } from 'react'
import { MediaImageStandard } from 'components/Contentful'
import styles from './HandwrittenText.module.scss'

const HandwrittenText = ({ alt, content }) => {
  const [loaded, setLoaded] = useState(false)

  return (
    <div className={styles['handwritten']} data-loaded={loaded}>
      <MediaImageStandard alt={alt} content={content} onLoad={() => setLoaded(true)} />
    </div>
  )
}

export { HandwrittenText }
