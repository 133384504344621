import { removeUrlParams } from 'utils/shared'

/**
 * Converts a slug-like path to a human-readable string.
 *
 * Example usage:
 * const path = "hello-world/example-path";
 * const converted = unslug(path);
 * console.log(converted);
 * Output: "Hello World Example Path"
 *
 * @param {string} path - The slug-like path to be converted.
 * @returns {string} - The converted human-readable string.
 */
const unslug = (path) => {
  const upper = (pathPart) => {
    return pathPart.charAt(0).toUpperCase() + pathPart.slice(1)
  }
  return removeUrlParams(path).split('/').map(upper).join(' ').split('-').map(upper).join(' ')
}

export { unslug }
