import { Typography, LinkButton, Link } from 'components/Contentful'
import {
  Card,
  CardMedia,
  CardLeadership,
  CardText,
  CardValueProp,
  CardProduct,
} from 'components/Cards'

export const sectionContentTypes = {
  CardValueProp,
  CardMedia,
  Typography,
  CardLeadership,
  CardProduct,
  Card,
  CardText,
  Link,
  LinkButton,
}
