import { useContext, useRef, useState } from 'react'
import { useMemoizedContentGetter } from 'utils/contentful'
import useSsrViewportCheck from 'hooks/useSsrViewportCheck/useSsrViewportCheck'
import Section from 'components/Section'
import { LayoutContext } from 'providers/LayoutProvider'
import { BREAKPOINTS } from 'constants/viewport'
import { Eyebrow } from 'components/Eyebrow'
import { Typography } from 'components/Contentful'
import { Button } from 'components/Button'

import styles from './DebtQuiz.module.scss'
import classNames from 'classnames'
import Quiz from './Quiz'
import { AnalyticsContext } from 'providers/AnalyticsProvider'
import { useTriggerEvents } from 'hooks/useTriggerEvents'
import { handleTrackAndReactEvent } from 'utils/analytics'

function DebtQuizModule({
  title,
  subTitle,
  eyebrow,
  background,
  jsonConfig,
  cta,
  questionGroup1,
  questionGroup2,
  questionGroup3,
  questionGroup4,
  debtQuizId,
}) {
  const { fireAndForget, fireAndForgetPage } = useTriggerEvents()
  const { dispatch } = useContext(AnalyticsContext)
  const { quiz_name } = jsonConfig
  const { state: { isMobileUA } = {} } = useContext(LayoutContext)
  const isMobile = useSsrViewportCheck(isMobileUA, BREAKPOINTS.xl)
  const textContainerRef = useRef(null)
  const backgroundConfig = jsonConfig?.background ?? 'bgDefault'
  const maxScoreValue = jsonConfig?.maxScoreValue ?? 900
  const [startQuiz, setStartQuiz] = useState(false)

  const TriggerNewPageEvent = (question, event) => {
    dispatch({
      type: 'SET_QUIZ_PAGE_NAME',
      payload: {
        quiz_page: `${quiz_name} - Question ${question}`,
      },
    })
    fireAndForgetPage({
      event_type: 'page',
      event_name: `Achieve-Web | ${quiz_name} - Question ${question} - Page Loaded`,
      page_name: `Achieve-Web | ${quiz_name} - Question ${question}`,
      quiz_page: `${quiz_name} - Question ${question}`,
      ...handleTrackAndReactEvent(event, {}),
    })
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }, 0)
  }

  const TriggerResult = (question, quiz_answer, event) => {
    fireAndForget({
      event_type: 'button_click',
      click_id: 'Continue',
      click_position: '0',
      click_text: 'Achieve-Web | Continue',
      click_type: 'Button Click',
      event_name: `Achieve-Web | ${quiz_name} - Question ${question} - Page Loaded`,
      page_name: `Achieve-Web | ${quiz_name} - Question ${question}`,
      quiz_answer,
      quiz_page: `${quiz_name} - Question ${question}`,
      nav_link_section: 'Quiz Question',
      track_event: 'page_nav_click',
      ...handleTrackAndReactEvent(event, {}),
    })
  }

  const TriggerResultPage = (result, score, event) => {
    dispatch({
      type: 'SET_QUIZ_PAGE_NAME',
      payload: {
        quiz_page: `${quiz_name} - ${result}`,
        quiz_score: score,
      },
    })
    fireAndForgetPage({
      event_type: 'page',
      event_name: `Achieve-Web | ${quiz_name} - ${result} - Page Loaded`,
      page_name: `Achieve-Web | ${quiz_name} - ${result}`,
      quiz_page: `${quiz_name} - ${result}`,
      quiz_score: score,
      ...handleTrackAndReactEvent(event, {}),
    })
  }

  const {
    heroVariant,
    backgroundObjectPosition = 'right',
    backgroundObjectPositionMobile = 'bottom',
    titleVariantMobile = 'headingLg',
    titleVariant = 'displayXl',
    subTitleVariantMobile = 'bodyLg',
    subTitleVariant = 'bodyLg',
    showPattern = false,
    textAlign,
  } = jsonConfig
  const currentTitleVariant = isMobile ? titleVariantMobile : titleVariant
  const currentSubTitleVariant = isMobile ? subTitleVariantMobile : subTitleVariant
  return (
    <section className={styles['subnav-outer-container']}>
      {!startQuiz ? (
        <Section
          className={styles['section']}
          data-testid="closer-fullbg-section"
          data-narrow={true}
          data-hero-variant={heroVariant}
          mediaContent={background?.mediaContent}
          middleMediaContent={background?.mobileMediaContent}
          mobileMediaContent={background?.mobileMediaContent}
          backgroundImageAlt={background?.mediaAltText}
          showPattern={showPattern}
          backgroundObjectPosition={
            isMobile ? backgroundObjectPositionMobile : backgroundObjectPosition
          }
          variant={backgroundConfig}
          preloadBackground
        >
          <div className={styles['content-container']}>
            <div
              className={styles['text-container']}
              ref={textContainerRef}
              style={{
                textAlign: isMobile ? textAlign?.mobile ?? 'center' : textAlign?.desktop ?? 'left',
              }}
              data-testid="debt-quiz-text-container"
            >
              {eyebrow && (
                <Eyebrow
                  content={eyebrow?.textContent}
                  fontWeight="bold"
                  component="h1"
                  className={classNames(styles['eyebrow'], {
                    [styles[`font-align-${eyebrow?.position}`]]: Boolean(eyebrow?.position),
                  })}
                />
              )}
              {title && (
                <Typography
                  content={title?.textContent}
                  variant={currentTitleVariant}
                  fontWeight="bold"
                  className={classNames(
                    styles[`${eyebrow?.textContent ? 'title' : 'title-no-eyebrow'}`],
                    styles[`font-${title?.fontColor?.toLowerCase()}`]
                  )}
                />
              )}
              {subTitle && (
                <Typography
                  content={subTitle?.textContent}
                  variant={currentSubTitleVariant}
                  className={classNames(
                    styles['sub-title'],
                    styles[`font-${subTitle?.fontColor?.toLowerCase()}`]
                  )}
                />
              )}
              {cta && (
                <div className={styles['button-container']}>
                  <Button
                    color="primary"
                    variant="contained"
                    data-testid="skinny-form-cta"
                    fullWidth={isMobile}
                    onClick={(event) => {
                      setStartQuiz(true)
                      TriggerNewPageEvent(1, event)
                    }}
                    track={{
                      click_id: 'start-quiz',
                      click_text:
                        cta?.textContent?.json?.content?.[0]?.content?.[0]?.value || 'Start Quiz',
                      click_type: 'Button Click',
                      click_position: 1,
                      event_type: 'button_click',
                      nav_link_section: 'Quiz Start',
                    }}
                  >
                    <Typography content={cta?.textContent} variant="bodyLg" fontWeight="bold" />
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Section>
      ) : (
        <Quiz
          questionGroup1={questionGroup1}
          questionGroup2={questionGroup2}
          questionGroup3={questionGroup3}
          questionGroup4={questionGroup4}
          maxScoreValue={maxScoreValue}
          isMobile={isMobile}
          debtQuizId={debtQuizId}
          TriggerNewPageEvent={TriggerNewPageEvent}
          TriggerResult={TriggerResult}
          TriggerResultPage={TriggerResultPage}
        />
      )}
    </section>
  )
}

const DebtQuiz = ({ content }) => {
  const {
    title,
    subTitle,
    eyebrow,
    background,
    cta,
    questionGroup1,
    questionGroup2,
    questionGroup3,
    questionGroup4,
    configJson: { jsonContent },
  } = useMemoizedContentGetter(content, [
    'title',
    'subTitle',
    'eyebrow',
    'background',
    'cta',
    'questionGroup1',
    'questionGroup2',
    'questionGroup3',
    'questionGroup4',
    'configJson',
  ])
  return (
    <DebtQuizModule
      title={title}
      subTitle={subTitle}
      eyebrow={eyebrow}
      background={background}
      cta={cta}
      questionGroup1={questionGroup1}
      questionGroup2={questionGroup2}
      questionGroup3={questionGroup3}
      questionGroup4={questionGroup4}
      isMobileUA={content?.isMobileUA}
      jsonConfig={jsonContent}
      debtQuizId={content?.fields?.sys?.id}
    />
  )
}

export { DebtQuiz, DebtQuizModule }
export default DebtQuiz
