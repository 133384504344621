import { gql } from '@apollo/client'

export const GET_CONTENT_BY_ID = gql`
  query ContentTypeRichText($id: String!) {
    contentTypeRichText: contentTypeRichText(id: $id) {
      name
      fieldName
      uiComponent
      textContent {
        json
        links {
          entries {
            inline {
              ... on StyledText {
                sys {
                  id
                }
                plainText
                styledText
                variation
                identifier
              }
              ... on VideoModal {
                sys {
                  id
                }
                altText
                title
                source
                autoplay
                buttonCta
                videoPlayer {
                  fieldName
                  uiComponent
                  source
                  thumbnail {
                    url
                    fileName
                    height
                    width
                  }
                  altText
                  autoplay
                }
              }
            }
          }
          assets {
            hyperlink {
              sys {
                id
              }
              url
              title
              description
              contentType
              fileName
            }
          }
        }
      }
    }
  }
`

export const GET_GLOBAL_VARIABLES = gql`
  query fetchGlobalVariables {
    jsonFieldCollection(where: { name: "Frontend Global Variables" }) {
      items {
        jsonContent
      }
    }
  }
`
