import { Grid } from '@achieve/ascend'
import styles from './NavArticlesSection.module.scss'
import Section from 'components/Section'
import { get as _get } from 'lodash-es'
import { contentMap } from 'utils/contentful'
import { Typography } from 'components/Contentful'
import { AchieveLink } from 'components/AchieveLink'
import { useRouter } from 'next/router'
import { useEffect, useReducer, useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import { normalizeContent } from 'utils/shared/hacks'

function NavArticlesSection({ content }) {
  const router = useRouter()

  const reducerFunction = (state, action) => {
    switch (action.type) {
      case 'setData':
        return { ...state, ...action.payload }
      default:
        return state
    }
  }

  const readContentData = (content) => {
    if (!content) return {}
    const navData = _get(content, 'fields.navArticlesData.sectionContentsCollection.items')
    if (navData) {
      let title, copy
      let itemActive = null
      const itemList = navData
        ?.filter((item) => item.__typename === 'PageSection')
        .map((item, index) => {
          let { title, copy, link } = contentMap(item.sectionContentsCollection.items, [
            'title',
            'copy',
            'link',
          ])
          if (router.asPath.split('?')?.[0] === link?.linkHref) itemActive = index
          return { title, copy, link, itemActive }
        })

      if (itemActive) {
        title = itemList[itemActive].title
        copy = itemList[itemActive].copy
      }

      if (!title && !copy) {
        const dataItem = contentMap(navData, ['title', 'copy'])
        title = dataItem.title
        copy = dataItem.copy
      }

      return {
        title: title,
        copy: copy,
        itemActive,
        list: itemList,
      }
    } else {
      let { title, copy } = contentMap(normalizeContent(content?.fields?.sectionContents), [
        'title',
        'copy',
      ])
      return {
        title,
        copy,
        list: [],
      }
    }
  }
  const data = readContentData(content)

  const [{ navOpen, itemActive, title, copy, list }, dispatch] = useReducer(reducerFunction, {
    navOpen: true,
    itemActive: data?.itemActive,
    title: data?.title,
    copy: data?.copy,
    list: data?.list ?? [],
  })

  const [hideSubtitle, setHideSubtitle] = useState(false)
  useEffect(() => {
    const handleScroll = () => {
      if (list?.length > 0) {
        setHideSubtitle(window.scrollY > 0)
        // if (navOpen) setNavOpen(!window.scrollY > 0)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [list?.length, navOpen])

  const setNavOpen = (navOpen) => [
    dispatch({
      type: 'setData',
      payload: {
        navOpen,
      },
    }),
  ]

  const showSubnav = list?.length > 0 && (itemActive || _get(content, 'fields.allPage') === true)

  return (
    <Section
      contain={false}
      className={styles['nav-articles-container']}
      data-sticky={list?.length > 0}
      data-testid="nav-articles-section"
    >
      {(title || copy) && (
        <Grid container className={styles['nav-articles-section']}>
          <Grid item xs={12} className={styles['nav-articles-title']}>
            {title && (
              <Typography
                content={title?.textContent}
                variant={'headingMd'}
                data-testid="nav-articles-title"
              />
            )}
            {showSubnav && !hideSubtitle && (
              <>
                {navOpen ? (
                  <ChevronUp
                    className={styles['chevron-icon']}
                    size={24}
                    onClick={() => setNavOpen(!navOpen)}
                    data-testid="nav-articles-chevron-up"
                  />
                ) : (
                  <ChevronDown
                    className={styles['chevron-icon']}
                    size={24}
                    onClick={() => setNavOpen(!navOpen)}
                    data-testid="nav-articles-chevron-down"
                  />
                )}
              </>
            )}
          </Grid>
          {!hideSubtitle && copy && (
            <Grid item xs={12} marginTop={1} className={styles['nav-articles-subtitle']}>
              <Typography
                content={copy?.textContent}
                variant={'bodySm'}
                data-testid="nav-articles-subtitle"
              />
            </Grid>
          )}
        </Grid>
      )}
      {showSubnav && !hideSubtitle && (
        <div
          className={styles['nav-articles-list']}
          data-open={navOpen}
          data-testid="nav-articles-list"
        >
          {list?.map((item, index) => {
            const title = _get(item, 'title.textContent.json.content[0].content[0].value')
            const link = _get(item, 'link.linkHref')
            return (
              <div
                key={`item-nav-articles-${index}`}
                className={styles['nav-articles-item-cotainer']}
                data-testid={`nav-articles-item-${index}`}
              >
                <div className={styles['nav-articles-item']} data-active={itemActive === index}>
                  <AchieveLink
                    href={`${router.basePath}${link}`}
                    track={{
                      list_name: 'Breadcrumb',
                      click_text: `${title}`,
                      click_id: `${title}`,
                    }}
                    underline="hover"
                    color="inherit"
                    data-testid="nav-articles-item-link"
                  >
                    <Typography
                      content={title}
                      variant="labelSm"
                      data-testid="nav-articles-item-text"
                    />
                  </AchieveLink>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </Section>
  )
}
export { NavArticlesSection }
export default NavArticlesSection
