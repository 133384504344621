import { useMemoizedContentGetter } from 'utils/contentful'
import { MediaImageStandard, Typography } from 'components/Contentful'
import Section from 'components/Section'
import { AchieveTheme, Box } from '@achieve/ascend'
import { BLOCKS } from '@contentful/rich-text-types'
import { BREAKPOINTS } from 'constants/viewport'
import styles from './AboutUsHero.module.scss'
import useSsrViewportCheck from 'hooks/useSsrViewportCheck/useSsrViewportCheck'

const AboutUsHero = ({ content }) => {
  const { aboutUsHeroTitle, aboutUsHeroSubTitle, aboutUsHeroImage } = useMemoizedContentGetter(
    content,
    ['aboutUsHeroTitle', 'aboutUsHeroSubTitle', 'aboutUsHeroImage']
  )
  const isMobile = useSsrViewportCheck(content?.isMobileUA, BREAKPOINTS.lg)

  const mediaImageContent = !isMobile
    ? aboutUsHeroImage?.mediaContent
    : aboutUsHeroImage?.mobileMediaContent || aboutUsHeroImage?.mediaContent

  return (
    <Section data-testid="about-us-hero-section" className={styles['section']} contain={false}>
      <Box className={styles['about-us-hero-container']} data-testid="about-us-hero-background">
        <div className={styles['media-img']}>
          <MediaImageStandard
            content={mediaImageContent}
            layout="fill"
            objectFit="cover"
            objectPosition="top left"
            priority={true}
            quality={isMobile ? 100 : 75}
          />
        </div>
        <div className={styles['content']}>
          <div className={styles['info']}>
            <Typography
              content={aboutUsHeroTitle?.textContent}
              className={styles['title']}
              color={
                !isMobile
                  ? AchieveTheme?.ascend?.colors?.neutral?.[900]
                  : AchieveTheme?.ascend?.colors?.neutral?.[0]
              }
              fontWeight="bold"
              variantOverride={{ [BLOCKS.HEADING_1]: !isMobile ? 'displayXl' : 'headingLg' }}
              data-testid="about-us-hero-title"
            />
            <Typography
              content={aboutUsHeroSubTitle?.textContent}
              className={styles['subtitle']}
              fontWeight={!isMobile ? 'medium' : 'regular'}
              color={
                !isMobile
                  ? AchieveTheme?.ascend?.colors?.neutral?.[900]
                  : AchieveTheme?.ascend?.colors?.neutral?.[0]
              }
              data-testid="about-us-hero-subtitle"
              variantOverride={{ [BLOCKS.PARAGRAPH]: 'headingMd' }}
            />
          </div>
        </div>
      </Box>
    </Section>
  )
}
export { AboutUsHero }
export default AboutUsHero
