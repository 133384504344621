/**
 * Known hacks that need to be changed.
 * Once it is no longer a hack, move it to the correct place it should live in the code
 */
import stringify from 'json-stringify-safe'

/**
 * Returns a copy of the provided JSON content with self-referencing objects removed.
 * @param {object} jsonContent - The JSON content to be normalized
 * @returns {object} - A normalized copy of the JSON content
 */
function normalizeContent(jsonContent) {
  if (!jsonContent) return null
  return JSON.parse(stringify(jsonContent))
}

export { normalizeContent }
