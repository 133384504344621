import { useMemoizedContentGetter } from 'utils/contentful'
import { LinkButton, MediaImageStandard, Typography } from 'components/Contentful'
import Section, { SECTION_VARIANTS } from 'components/Section'
import styles from './MoloHero.module.scss'
import { AchieveTheme, Grid } from '@achieve/ascend'
import { BREAKPOINTS } from 'constants/viewport'
import { get as _get } from 'lodash-es'
import classNames from 'classnames'
import useSsrViewportCheck from 'hooks/useSsrViewportCheck/useSsrViewportCheck'
import AppStoreButtons from 'components/AppStoreButtons/AppStoreButtons'

function MoloHero({ content }) {
  const {
    button_apple: appleButton,
    button_google: googleButton,
    copy,
    eyebrow,
    config,
    heroImage,
    image_apple: appleImage,
    image_google: googleImage,
    title,
    ctaButton,
  } = useMemoizedContentGetter(content, [
    'button_apple',
    'button_google',
    'copy',
    'eyebrow',
    'config',
    'heroImage',
    'image_apple',
    'image_google',
    'title',
    'ctaButton',
  ])

  const variation = config?.jsonContent?.variation || 'default'
  const heroImageContent = _get(heroImage, 'mediaContent', null)
  const imageAlt = _get(heroImage, 'mediaAltText', null)
  const ctaText = _get(ctaButton, 'linkText.json.content[0].content[0].value', '')
  const ctaHref = _get(ctaButton, 'linkHref', '')

  const isMobile = useSsrViewportCheck(
    content?.isMobileUA,
    variation === 'DebtCheckup' ? BREAKPOINTS.lg : BREAKPOINTS.md
  )

  const variationsConfig = {
    default: {
      sectionVariant: SECTION_VARIANTS.bgShadowPrimaryHighlightBlue,
      sectionStyle: null,
      GridContentSize: 6,
      GridImageSize: 6,
      titleSizeVariant: 'displayXl',
      titleSizeMobileVariant: 'headingLg',
      layoutImage: 'responsive',
    },
    GoodApp: {
      sectionVariant: SECTION_VARIANTS.bgShadowPrimaryHighlightWhiteBlue,
      sectionStyle: styles['section-good-app'],
      GridContentSize: 5,
      GridImageSize: 7,
      titleSizeVariant: isMobile ? 'displayXl' : 'displayXl',
      layoutImage: 'responsive',
    },
    DebtCheckup: {
      sectionStyle: styles['section-debt'],
      GridContentSize: 6,
      GridImageSize: 6,
      titleSizeVariant: isMobile ? 'headingLg' : 'displayXl',
      layoutImage: isMobile ? 'responsive' : 'raw',
      objectFitImage: isMobile ? 'contain' : null,
    },
  }

  return (
    <Section
      className={classNames(styles['section'], ...[variationsConfig[variation].sectionStyle])}
      childContainerClassName={styles['section-inner']}
      data-testid="molo-hero-section"
      data-narrow={true}
      variant={variationsConfig[variation].sectionVariant}
      contain
    >
      <Grid
        className={styles['content-container']}
        container
        justifyContent="center"
        alignItems={'initial'}
        direction={isMobile ? 'column' : 'row'}
      >
        <Grid
          className={styles['copy']}
          item
          xs={12}
          lg={variationsConfig[variation].GridContentSize}
        >
          {eyebrow && (
            <Typography
              content={eyebrow?.textContent}
              variant="bodySm"
              fontWeight="bold"
              component="h1"
              data-testid="molo-hero-eyebrow"
            />
          )}
          {title && (
            <Typography
              content={title?.textContent}
              color={AchieveTheme?.ascend?.colors?.blue[500]}
              responsive={true}
              variant={variationsConfig[variation].titleSizeVariant}
              mobileVariant={variationsConfig[variation].titleSizeMobileVariant}
              breakpoint="lg"
              fontWeight="bold"
              component="h2"
              data-testid="molo-hero-title"
            />
          )}
          {copy && (
            <Typography
              content={copy?.textContent}
              responsive={true}
              variant="bodyLg"
              mobileVariant="bodySm"
              breakpoint="lg"
              fontWeight="normal"
              component="p"
              data-testid="molo-hero-copy"
            />
          )}
          {ctaButton && (
            <LinkButton
              track={{
                list_name: 'Home Hero CTA click',
                click_id: ctaText,
                click_text: ctaText,
              }}
              content={{
                linkHref: ctaHref,
                linkText: ctaText,
                uiComponent: _get(ctaButton, 'uiComponent', ''),
              }}
              typographicOptions={{ variant: 'bodyLg', fontWeight: 'bold' }}
              variant="contained"
              color="primary"
              className={styles['slider-tool-cta']}
              fullWidth={isMobile}
            />
          )}
          <div className={styles['app-buttons-container']}>
            <AppStoreButtons
              navLinkSection="Hero"
              appleImage={appleImage}
              googleImage={googleImage}
              appleButton={appleButton}
              googleButton={googleButton}
              priority
            />
          </div>
        </Grid>

        {heroImageContent && (
          <Grid
            className={styles['hero-image-grid-item']}
            item
            xs={12}
            lg={variationsConfig[variation].GridImageSize}
          >
            <HeroImage
              imageContent={heroImageContent}
              alt={imageAlt}
              layout={variationsConfig[variation].layoutImage}
              objectFit={variationsConfig[variation].objectFitImage}
            />
          </Grid>
        )}
      </Grid>
    </Section>
  )
}

function HeroImage({ imageContent, alt, layout, objectFit }) {
  return (
    <div className={styles['hero-image-container']}>
      {imageContent && (
        <MediaImageStandard
          content={imageContent}
          alt={alt}
          layout={layout}
          objectFit={objectFit}
          width={340}
          priority
        />
      )}
    </div>
  )
}
export { MoloHero }
export default MoloHero
