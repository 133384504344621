import PageTitle from 'pageSections/PageTitle/PageTitle'
import StickySubNavWrapper from 'pageSections/StickySubNavWrapper/StickySubNavWrapper'

function PoliciesBody({ content, scrollRender = true }) {
  return <StickySubNavWrapper content={content} scrollRender={scrollRender} />
}

function PoliciesHero({ content }) {
  return <PageTitle content={content} />
}

export { PoliciesBody, PoliciesHero }
