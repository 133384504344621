import { BLOCKS } from '@contentful/rich-text-types'
import { Typography } from 'components/Contentful'
import { useMemoizedContentGetter } from 'utils/contentful'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'
import Section from 'components/Section'
import styles from './PageTitle.module.scss'

function PageTitle({ content }) {
  const { title } = useMemoizedContentGetter(content, ['title'])
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)

  return (
    <Section className={styles['hero-section']} data-testid="policies-hero-section">
      <Typography
        content={title.textContent}
        variantOverride={{
          [BLOCKS.HEADING_1]: isMobile ? 'displayLg' : 'displayXl',
        }}
        lineHeight={isMobile ? '51.8px' : '71.25px'}
      />
    </Section>
  )
}
export { PageTitle }
export default PageTitle
