import useIsSsr from 'hooks/useIsSsr/useIsSsr'
import { useViewportLargerThan, useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'
import { useRouter } from 'next/router'
import { MOBILE_DEVICE } from 'constants/devices'

/**
A custom hook that checks whether the viewport matches the given breakpoint
and handles server-side rendering (SSR).
@param {boolean} [defaultValue=true] - The default value to return when SSR is true. Defaults to true.
@param {string} [breakpoint='lg'] - The breakpoint to check against. Defaults to 'lg'.
@param {boolean} [larger=false] - The strategy to use when checking against the breakpoint. Can be either false for 'smaller' or true for 'larger'. Defaults to 'smaller'.
@returns {boolean} - Returns true if the viewport matches the given breakpoint or the default value when SSR is true.
*/
const useSsrViewportCheck = (defaultValue = true, breakpoint = BREAKPOINTS.lg, larger = false) => {
  const router = useRouter()
  const isSmaller = useViewportSmallerThan(breakpoint)
  const isLarger = useViewportLargerThan(breakpoint)
  const isSsr = useIsSsr()
  const { query } = router

  const getSsrValue = () => {
    // return default value if there is no query device value
    if (!query?.device) return defaultValue

    // For 'smaller than' breakpoint comparison, return `true` for mobile devices
    if (!larger) return query.device === MOBILE_DEVICE

    // For 'larger than' breakpoint comparison, return `true` for non-mobile devices
    return query.device !== MOBILE_DEVICE
  }

  if (isSsr) {
    return getSsrValue()
  }
  return !larger ? isSmaller : isLarger
}

export default useSsrViewportCheck
